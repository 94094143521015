

import { removePageScroll, addPageScroll, closeOnClickOutside } from 'javascript/lander/cf_utils'
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class ModalSidebarV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}



    mount() {
      closeOnClickOutside(this.element.querySelector('.elModalSidebar'), () => this.hide())
    }
    show() {
      removePageScroll()
      this.element.classList.remove('forceHide')
      const wrapper = this.element.querySelector('.elModalSidebar')
      setTimeout(() => {
        wrapper.classList.add('elModalSidebarSlideAnimation')
      })
    }
    hide() {
      addPageScroll()
      this.element.classList.add('forceHide')
      const wrapper = this.element.querySelector('.elModalSidebar')
      wrapper.classList.remove('elModalSidebarSlideAnimation')
    }



}

registerComponent('ModalSidebar/V1', ModalSidebarV1)
window["ModalSidebarV1"] = ModalSidebarV1

